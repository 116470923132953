<template>
  <v-card class="side-cart" :elevation="0" tile>
    <div class="side-cart__container">
      <div
        class="d-flex justify-space-between align-center pa-4 side-cart__header"
      >
        <h3 class="product-ttl">Basket</h3>
        <v-btn
          @click="$emit('toggleSideCart', false)"
          :ripple="false"
          class="pa-0"
          text
        >
          <span>Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <div class="side-cart__body pa-4">
        <div
          v-for="(item, index) in cartItems"
          :key="index"
          class="d-flex text-center justify-space-between product cursor-pointer"
          @click="$emit('goToProduct', item.product.slug)"
        >
          <div class="side-cart__img-block">
            <img
              v-if="item?.variation?.id"
              :src="
                item.variation?.product_gallery?.length &&
                baseUrl + item.variation?.product_gallery[0]
              "
              width="89"
              height="83"
              :alt="getImageName(item.variation?.product_gallery[0])" :title="getImageName(item.variation?.product_gallery[0])" 
            />
            <img
              v-else
              :src="item.product && baseUrl + item.product.photo"
              width="89"
              height="83"
              :alt="getImageName(item.product.photo)"
              :title="getImageName(item.product.photo)"
            />
          </div>

          <div class="product__texts">
            <h3 class="product__title">
              {{ item.product?.name[localeLang] }}
            </h3>
            <span
              v-if="item.color || item?.variation?.title?.ru"
              class="product__options d-block"
            >
              Цвет: {{ getColor(item) }}
            </span>
            <div
              v-if="item.isSpecialProduct || item?.variation"
              class="product__options d-flex flex-column"
            >
              <span v-show="item.size.size || item.size" class="price">
                Размер: {{ item.isSpecialProduct ? item.size : `${item.size.size}` }}
              </span>
              <span class="count">Колличество: {{ item.size.count || item.count }}</span>
              <div>
                <span
                  v-show="item?.size?.price?.discount?.view !== '0'"
                  class="price old_price notranslate"
                >
                  {{ item?.size?.price?.view }}
                  {{ getCurrency(item) }}
                </span>
              </div>
              <span class="price">
                <span class="value-price notranslate">
                  {{ productPrice(item) }} {{ getCurrency(item) || 'KGS' }}
                </span>
              </span>
            </div>
          </div>

          <v-btn
            class="px-0 d-flex align-start"
            icon
            :ripple="false"
            @click.stop="$emit('removeCartItem', item.cartId)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <!--  start artisan    -->

      <div
          v-if="isArtisanSelected"
          class="side-cart__body pa-4"
      >
        <div
            class="d-flex text-center justify-space-between product cursor-pointer"
        >
          <div class="side-cart__img-block">
            <img
                :src="baseUrl + artisan.profilePicture"
                width="89"
                height="83"
                :alt="getImageName(artisan.profilePicture)"
                :title="getImageName(artisan.profilePicture)"
            />
          </div>

          <div>
            <h3 class="product__title">
              {{ `${artisan.firstName} ${artisan.lastName}` }}
            </h3>
            <span
                class="product__options d-block"
            >
              {{ artisan.position }}
            </span>
          </div>

          <v-btn
              class="px-0 d-flex align-start"
              icon
              :ripple="false"
              @click.stop="removeArtisan"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- Moda-total -->

      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <div
          class="side-cart__footer container-fluid px-3 pb-12"
      >
        <div
            v-if="!isSpecialProduct"
            class="columns"
        >
          <span>Summary:</span>

          <div>
            <span class="notranslate">
              {{ totalPrice }}
              {{ cartItems[0]?.size?.currency?.currency?.currency || 'KGS'}}
            </span>
          </div>
        </div>
        <div>
          <!-- <button
              v-if="!isSpecialProduct"
              type="button"
              class="btn view mx-auto mb-3"
              data-dismiss="modal"
              @click="viewBasket"
          >
            <span>VIEW BASKET</span>
          </button> -->
          <button
              v-if="isSpecialProduct && !isArtisanSelected"
              type="button"
              class="btn view mx-auto mb-6"
              data-dismiss="modal"
              @click="goToArtisans"
          >
            <span>Choose the craftsman</span>
          </button>
          <button
              v-if="!isSpecialProduct || isArtisanSelected"
              type="button" class="btn view mx-auto mb-6"
              @click="order"
          >
            <span>ORDER</span>
          </button>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'

const { mapState: State_Auth } = createNamespacedHelpers('auth')
const { mapState: State_Artisan, mapActions } = createNamespacedHelpers('artisan')
export default {
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeLang: '',
      baseUrl: '',
    }
  },
  computed: {
    ...State_Artisan(['artisan']),
    ...State_Auth(['localeLang']),
    isSpecialProduct () {
      return this.cartItems.find(item => item.isSpecialProduct)?.isSpecialProduct
    },
    isArtisanSelected () {
      return Object.keys(this.artisan).length
    },
    totalPrice() {
      let total = 0

      const result = this.cartItems.map((i) => {
        if (i.isSpecialProduct) {
          return {
            sum: i.product.sale_cost
          }
        } else {
          if (+i?.size?.price?.discount?.view) {
            return {
              sum:
                  i.size.count * (i.size.price.view - i.size.price.discount.view),
            }
          } else {
            return {
              sum: i.size.count * i.size.price.view,
            }
          }
        }
      })
      total = result.reduce((acc, curr) => {
        return (acc += curr.sum)
      }, 0)
      return total.toFixed(2)
    },
    totalCount() {
      let total = 0
      this.cartItems.forEach((item) => {
        total += item.colors.sizes.reduce((sum, curr) => sum + curr.count, 0)
      })
      return total
    },
  },
  methods: {
    ...mapActions(['removeArtisan']),
    getImageName,
    order () {
      localStorage.setItem('retailOrder', JSON.stringify(this.cartItems))
      if (this.isSpecialProduct && this.isArtisanSelected) {
        this.$router.push({ path: '/order/basket' })
      } else {
        this.$router.push({ path: '/order/registration' })
      }
    },
    goToArtisans () {
      if (this.isSpecialProduct) {
        if (this.$route.name === 'Artisans') {
          return
        }

        return this.$router.push({ name: 'Artisans' })
      }
    },
    viewBasket () {
      this.$router.push({ path: '/order/basket' })
    },
    getCurrency (item) {
      return item.size?.currency?.currency?.currency
    },
    getColor (item) {
      if (!item.isSpecialProduct) {
        return item?.variation?.title?.ru
      }

      return item.color.name
    },
    productPrice(item) {
      if (!item.isSpecialProduct) {
        let price = item?.size?.price?.view
        let discount = item?.size?.price?.discount?.view
        return Number(price - discount).toFixed(2)
      }

      return item.product.sale_cost.toFixed(2)
    },
  },
  mounted() {
    this.activeLang = this.$store.state.language
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style lang="scss" scoped>
.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 344px;
  height: 100%;
  z-index: 20;

  &__img-block {
    width: 89px;
    height: 83px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__body {
    overflow: auto;
  }
  &__footer {
    margin-top: auto;

    .columns {
      span {
        font-size: 24px;
        color: #666666;
        font-family: 'Roboto Condensed';
      }
      & > span {
        font-weight: 500;
        font-family: 'Alegreya Sans';
      }
    }
  }
  .product {
    &__title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    &__options {
      font-size: 14px;
      color: #b2b2b2;
    }
    &__title,
    &__options {
      text-align: left;
    }
  }
  .view {
    background-color: #cfebcc;
  }
  .ordering {
    background-color: #eeeeee;
  }
}
.product {
  &__title {
    font-size: 24px;
    font-weight: 500;
  }
  .count,
  .delimetr {
    font-size: 14px;
    color: #9a9a9a;
  }
  .price {
    font-size: 14px;
    color: #666666;
  }
  .old_price {
    font-size: 14px;
    text-decoration: line-through;
    color: #000;
  }
}
.v-btn--icon:before {
  content: none;
}
.mini-card-imgs {
  margin-left: 10px !important;
}
.product__texts {
  width: 90% !important;
  padding-left: 20px;
}
</style>
